<template>
  <div :style="'background-color: ' + getBgColor()" class="flex items-center rounded-[12px] px-3 h-[35px] md:h-[41px] w-fit">
    <span :style="'color: ' + getTextColor()" class="font-montserrat-normal text-[16px] md:text-[24px] font-bold">{{
      text
    }}</span>
  </div>
</template>

<script lang="ts" setup>
import {useIsDarkMode} from '~/composables/common'

interface ComponentProps {
  text: string
  bgColor: string
  darkBgColor: string
  textColor: string
  darkTextColor: string
}

const props = defineProps<ComponentProps>()

const isDarkMode = useIsDarkMode()

const getBgColor = () => {
  return isDarkMode.value ? props.darkBgColor : props.bgColor
}

const getTextColor = () => {
  return isDarkMode.value ? props.darkTextColor : props.textColor
}
</script>
