<template>
  <div
    class="flex border md:border-none border-cs-light-grey dark:border-cs-medium-grey py-8 px-6 md:p-5 rounded-[12px] h-[204px] hover:bg-[#F4FFFF] group cursor-pointer"
    @click="navigate"
  >
    <NuxtImg
      :alt="$t(activity.title)"
      :src="activity.icon"
      class="h-fit mb-5 dark:hidden dark:group-hover:block"
      format="webp"
      loading="lazy"
      preload
      quality="100"
      sizes="sm:250px md:250px lg:250px"
    />
    <NuxtImg
      :alt="$t(activity.title)"
      :src="activity.iconWhite"
      class="h-fit mb-5 hidden dark:block dark:group-hover:hidden"
      format="webp"
      loading="lazy"
      preload
      quality="100"
      sizes="sm:250px md:250px lg:250px"
    />
    <div class="flex flex-col ml-5 justify-between">
      <div class="flex flex-col">
        <span
          class="font-montserrat-normal text-cs-black dark:text-white dark:group-hover:text-cs-black text-[20px] font-bold leading-[30px]"
        >
          {{ $t(activity.title) }}
        </span>
        <p
          class="mt-[10px] font-montserrat-normal text-cs-black dark:text-white dark:group-hover:text-cs-black text-[16px] font-medium leading-6 w-full md:w-[244px]"
        >
          {{ $t(activity.description) }}
        </p>
      </div>
      <div class="hidden group-hover:flex items-center">
        <span class="text-[#39C0C4] text-sm leading-6">{{ $t('landing.activityAccess') }} </span>
        <IconArrow />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import IconArrow from 'assets/icons/right-arrow.svg'

interface ComponentProps {
  activity: any
}

const props = defineProps<ComponentProps>()

const navigate = () => {
  navigateTo(props.activity?.url)
}
</script>
