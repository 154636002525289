<template>
  <div>
    <canvas ref="canvas" :height="height" :width="width"></canvas>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Rive',
  props: {
    src: String,
    width: Number,
    height: Number
  },
  mounted() {
    if (process.client) {
      const rive = window.rive

      new rive.Rive({
        canvas: this.$refs.canvas,
        src: this.$props.src,
        width: this.$props.width,
        height: this.$props.height,
        autoplay: true,
        layout: new rive.Layout({fit: rive.Fit.Cover})
      })
    }
  }
})
</script>
