<template>
  <div class="w-[340px]">
    <Carousel
      v-if="data"
      :ref="carousel"
      :autoplay="AUTO_PLAY_RATE"
      :items-to-show="getItemsToShow()"
      :transition="CAROUSEL_UPDATE_RATE"
      :wrapAround="true"
      @slide-end="updateSlide"
    >
      <Slide v-for="(activity, index) in data" :key="index">
        <LandingActivity :activity="activity" class="h-[258px] w-[320px]" />
      </Slide>
    </Carousel>
    <div class="flex my-5 items-center justify-center gap-[10px] md:hidden">
      <div
        v-for="number in data?.length"
        :class="number - 1 === currentSlide ? 'bg-black dark:bg-cs-medium-grey' : ''"
        class="w-2 h-2 rounded-full border border-black dark:border-cs-medium-grey"
      ></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {isDesktopScreen} from '~/util/screen'

interface ComponentProps {
  data: any
}

defineProps<ComponentProps>()

const CAROUSEL_UPDATE_RATE = 500 // 500 milliseconds
const AUTO_PLAY_RATE = 5000 // 5 second

const carousel = ref(null)
const currentSlide = ref<number>(0)

const getItemsToShow = () => {
  return isDesktopScreen.value ? 2 : 1
}

const updateSlide = (data: any) => {
  currentSlide.value = data.currentSlideIndex
}
</script>
<script lang="ts">
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css'
import {Carousel, Slide} from 'vue3-carousel'

export default {
  name: 'App',
  components: {
    Carousel,
    Slide
  }
}
</script>
