<template>
  <div
    class="bg-[linear-gradient(350deg,#33BAFE_6.24%,#6369FF_91.89%)] w-full h-[358px] md:h-[432px] lg:h-[291px] flex items-center justify-center relative"
  >
    <NuxtImg
      alt="Background Left"
      class="absolute left-0 top-0 hidden lg:block h-[291px]"
      format="webp"
      loading="lazy"
      preload
      quality="100"
      src="/landing/stats-left-blur.png"
    />
    <NuxtImg
      alt="Background Left"
      class="absolute left-0 top-0 lg:hidden w-[351px]"
      format="webp"
      loading="lazy"
      preload
      quality="100"
      src="/landing/stats-left-blur-mobile.png"
    />
    <div class="hidden lg:grid grid-cols-4 justify-between absolute mr-auto ml-auto z-10">
      <LandingStatsItem
        :number="'+' + formatBigNumber(members)"
        class="lg:mr-10"
        data-aos="zoom-in"
        data-aos-delay="250"
        text="landing.statsMembers"
      />
      <LandingStatsItem
        :number="countries + '+'"
        class="lg:mr-10"
        data-aos="zoom-in"
        data-aos-delay="500"
        text="landing.statsCountries"
      />
      <LandingStatsItem
        :number="projects + '+'"
        class="lg:mr-10"
        data-aos="zoom-in"
        data-aos-delay="750"
        text="landing.statsProjects"
      />
      <LandingStatsItem
        :number="formatBigNumber(content)"
        class="lg:mr-10"
        data-aos="zoom-in"
        data-aos-delay="1000"
        text="landing.statsContent"
      />
    </div>
    <div class="flex lg:hidden justify-between items-center absolute mr-auto ml-auto z-10">
      <div class="flex flex-col">
        <LandingStatsItem :number="'+' + formatBigNumber(members)" class="lg:mr-10" text="landing.statsMembers" />
        <LandingStatsItem :number="projects + '+'" class="lg:mr-10" text="landing.statsProjects" />
      </div>
      <div class="w-px h-[200px] bg-white opacity-50 mx-5 md:mx-[30px]"></div>
      <div class="flex flex-col">
        <LandingStatsItem :number="countries + '+'" class="lg:mr-10" text="landing.statsCountries" />
        <LandingStatsItem :number="formatBigNumber(content)" class="lg:mr-10" text="landing.statsContent" />
      </div>
    </div>
    <NuxtImg
      alt="Background Right"
      class="absolute right-0 bottom-0 hidden lg:block h-[291px]"
      format="webp"
      loading="lazy"
      preload
      quality="100"
      src="/landing/stats-right-blur.png"
    />
    <NuxtImg
      alt="Background Right"
      class="absolute right-0 bottom-0 lg:hidden w-[229px]"
      format="webp"
      loading="lazy"
      preload
      quality="100"
      src="/landing/stats-right-blur-mobile.png"
    />
  </div>
</template>

<script lang="ts" setup>
import {formatBigNumber} from '~/util/format'

const members = 35000
const countries = 100
const projects = 170
const content = 1000
</script>
