<template>
  <div class="flex flex-col items-center w-full lg:w-[1440px] pt-[60px] md:pt-[100px]">
    <h2
      class="font-montserrat-normal text-cs-black dark:text-white text-[34px] md:text-[50px] lg:text-[58px] font-bold w-[320px] md:w-[608px] lg:w-[1014px] text-center"
      data-aos="zoom-in"
    >
      {{ $t('landing.activitiesTitle') }}
    </h2>
    <div
      class="my-[50px] font-montserrat-normal text-cs-black dark:text-white text-[16px] md:text-[20px] font-medium leading-[30px] w-[320px] md:w-[608px] lg:w-[694px] text-center"
      data-aos="zoom-in"
    >
      <p>
        {{ $t('landing.activitiesSubTitlePart') }}
      </p>
    </div>
    <div class="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-y-20 mx-5 md:mx-0">
      <LandingActivity v-for="activity in ACTIVITIES" :activity="activity" class="hidden md:flex" data-aos="zoom-in" />
      <LandingActivitiesGallery :data="ACTIVITIES" class="md:hidden" />
    </div>
    <div class="px-5 md:px-0 w-full flex justify-center">
      <Button
        :onClick="register"
        :text="$t('common.register')"
        class="mt-[50px] md:mt-20 mb-[30px] md:mb-[100px] w-full md:w-[300px] !py-4"
        data-aos="zoom-in"
        variant="light"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
interface ComponentProps {
  register: Function
}

defineProps<ComponentProps>()

const ACTIVITIES = [
  {
    description: 'landing.activityCommunityDescription',
    title: 'landing.activityCommunityTitle',
    icon: '/landing/activity-community.png',
    iconWhite: '/landing/activity-community-white.png',
    url: '/community-feed/all'
  },
  {
    description: 'landing.activityNewsDescription',
    title: 'landing.activityNewsTitle',
    icon: '/landing/activity-news.png',
    iconWhite: '/landing/activity-news-white.png',
    url: '/news-feed'
  },
  {
    description: 'landing.activityProjectsDescription',
    title: 'landing.activityProjectsTitle',
    icon: '/landing/activity-project.png',
    iconWhite: '/landing/activity-project-white.png',
    url: '/project-library/all'
  },
  {
    description: 'landing.activityEventsDescription',
    title: 'landing.activityEventsTitle',
    icon: '/landing/activity-events.png',
    iconWhite: '/landing/activity-events-white.png',
    url: '/events'
  },
  {
    description: 'landing.activityMarketDescription',
    title: 'landing.activityMarketTitle',
    icon: '/landing/activity-market.png',
    iconWhite: '/landing/activity-market-white.png',
    url: '/market-status'
  },
  {
    description: 'landing.activityWikiDescription',
    title: 'landing.activityWikiTitle',
    icon: '/landing/activity-wiki.png',
    iconWhite: '/landing/activity-wiki-white.png',
    url: '/wiki/vocabulary/a'
  }
]
</script>
