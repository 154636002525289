<template>
  <Modal :showModal="showModal">
    <div class="flex relative bg-white dark:bg-cs-dark-background rounded-[10px]">
      <IconClose
        class="absolute top-5 right-5 cursor-pointer self-end [&>g>path]:stroke-cs-black dark:[&>g>path]:stroke-white"
        @click="() => toggleModal(false)"
      />
      <div class="flex flex-col justify-center items-center w-[328px] md:w-[508px] py-[40px] lg:py-[50px] px-6 lg:px-[30px]">
        <div class="flex w-full justify-center mt-[10px] mb-[40px]">
          <IconPerson1 class="w-[62px] md:w-[78px] h-[61px] md:h-[78px] mr-[10px] md:mr-3" />
          <IconPerson2 class="w-[62px] md:w-[78px] h-[61px] md:h-[78px] mr-[10px] md:mr-3" />
          <IconPerson3 class="w-[62px] md:w-[78px] h-[61px] md:h-[78px] mr-[10px] md:mr-3" />
          <IconPerson4 class="w-[62px] md:w-[78px] h-[61px] md:h-[78px]" />
        </div>
        <span class="text-cs-black dark:text-white text-[36px] font-bold leading-[43px] text-center md:w-[310px]">{{
          $t('landing.joinCommunityTitle')
        }}</span>
        <span class="text-cs-black dark:text-white leading-5 text-sm block mt-5 text-center md:w-[310px]">
          {{ $t('landing.joinCommunityMessage', {username: username}) }}
        </span>
        <LandingRegisterComponent :showAppButtons="false" :toggleModal="toggleMethod" class="mt-5 md:w-[405px] lg:w-[424px]" />
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import IconClose from 'assets/icons/close.svg'
import IconPerson1 from 'assets/icons/landing/person1.svg'
import IconPerson2 from 'assets/icons/landing/person2.svg'
import IconPerson3 from 'assets/icons/landing/person3.svg'
import IconPerson4 from 'assets/icons/landing/person4.svg'
import {useGetUserNameFromReferralCode} from '~/composables/user'

interface ComponentProps {
  showModal: boolean
  toggleModal: Function
  referralCode: string | null
  toggleConfirmationModal: Function
}

const props = defineProps<ComponentProps>()

const {$showErrorToast} = useNuxtApp()

const username = ref<string>('-')

if (props.referralCode) {
  const {error, data: userData} = await useGetUserNameFromReferralCode(props.referralCode)
  if (error && error.value) {
    $showErrorToast(error)
  } else {
    username.value = userData.value.username
  }
}

const toggleMethod = (value: boolean, email: string) => {
  // Close referral popup
  props.toggleModal()
  // Open thank you modal
  props.toggleConfirmationModal(value, email)
}
</script>
