<template>
  <div
    class="bg-[linear-gradient(145deg,rgba(29,120,199,1)_35%,rgba(118,239,181,1)_100%)] w-full flex flex-col items-center justify-center relative py-[50px] md:py-[100px]"
  >
    <NuxtImg alt="Background Left" class="absolute left-0 top-0" preload quality="100" src="/landing/features-left-blur.svg" />
    <NuxtImg
      alt="Background Right"
      class="absolute right-0 bottom-0"
      preload
      quality="100"
      src="/landing/features-right-blur.svg"
    />
    <div class="flex flex-col items-center justify-center z-10">
      <h2
        class="font-montserrat-normal text-white text-[34px] md:text-[50px] lg:text-[58px] w-[320px] md:w-full text-center font-bold"
      >
        {{ $t('landing.featuresTitle') }}
      </h2>
      <div class="hidden md:flex flex-col items-center">
        <div class="flex flex-col md:flex-row items-center justify-center gap-5 mt-[80px]">
          <LandingFeaturesTab
            v-for="item in items"
            :isSelected="selectedTab === item.tab"
            :text="$t('landing.' + item.textKey)"
            @click="() => selectTab(item.tab)"
          />
        </div>
        <div
          class="block my-[50px] font-montserrat-normal text-white text-[16px] md:text-[20px] font-medium leading-[30px] w-[320px] md:w-[606px] lg:w-full text-center"
        >
          <p v-for="item in items" v-show="selectedTab === item.tab">
            {{ $t(item.subtitle) }}
          </p>
        </div>
        <ClientOnly>
          <RiveViewer
            v-for="item in items"
            v-show="selectedTab === item.tab"
            :canSeeRive="canSeeRive"
            :height="getHeight()"
            :src="item.riveSrc"
            :width="getWidth()"
            class="hidden md:block"
          />
        </ClientOnly>
      </div>
      <LandingFeaturesGallery
        :canSeeRive="canSeeRive"
        :data="items"
        :getHeight="getHeight"
        :getWidth="getWidth"
        class="md:hidden"
      />
      <div class="px-5 md:px-0 w-full md:w-[300px]">
        <div
          class="cursor-pointer flex justify-center items-center rounded-[5px] border border-cs-light-blue bg-cs-black py-4 mt-20"
          @click="register"
        >
          <span class="text-white text-[20px] leading-6">{{ $t('landing.featuresRegister') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {isDesktopScreen, isTabletScreen} from '~/util/screen'

interface ComponentProps {
  register: Function
}

defineProps<ComponentProps>()

const selectedTab = ref<string>('networking')
const canSeeRive = ref<boolean>(false)

const selectTab = (tab: string) => {
  selectedTab.value = tab
}

const items = [
  {
    tab: 'networking',
    textKey: 'featuresNetworking',
    riveSrc: '/landing/networking.riv',
    subtitle: 'landing.featuresSubtitleNetworking',
    imgSrc: '/landing/networking.png'
  },
  {
    tab: 'challenges',
    textKey: 'featuresChallenges',
    riveSrc: '/landing/challenges.riv',
    subtitle: 'landing.featuresSubtitleChallenges',
    imgSrc: '/landing/challenges.png'
  },
  {
    tab: 'livestreams',
    textKey: 'featuresLivestreams',
    riveSrc: '/landing/livestream.riv',
    subtitle: 'landing.featuresSubtitleLivestreams',
    imgSrc: '/landing/livestream.png'
  },
  {
    tab: 'content',
    textKey: 'featuresContent',
    riveSrc: '/landing/events.riv',
    subtitle: 'landing.featuresSubtitleContent',
    imgSrc: '/landing/events.png'
  }
]

const getWidth = () => {
  if (isDesktopScreen.value) {
    return 1041
  } else if (isTabletScreen.value) {
    return 608
  }
  return 320
}

const getHeight = () => {
  if (isDesktopScreen.value) {
    return 694
  } else if (isTabletScreen.value) {
    return 405
  }
  return 213
}

onMounted(() => {
  setTimeout(() => {
    canSeeRive.value = true
  }, 3000)
})
</script>
