<template>
  <div>
    <main class="flex flex-col items-center dark:bg-[#232323]">
      <LandingTopBanner :toggleModal="toggleConfirmationModal" />
      <LandingActivities :register="register" />
      <LandingFeatures :register="register" />
      <LandingCommunity />
      <LandingStats />
      <LandingRegister :toggleModal="toggleConfirmationModal" />
      <ModalLandingRegisterConfirmation
        :email="registrationEmail"
        :showModal="canSeeConfirmationModal"
        :toggleModal="toggleConfirmationModal"
      />
      <ModalLandingReferralRegister
        :referralCode="referral || null"
        :showModal="showReferralModal"
        :toggleConfirmationModal="toggleConfirmationModal"
        :toggleModal="toggleReferralModal"
      />
    </main>
  </div>
</template>

<script lang="ts" setup>
definePageMeta({
  layout: 'public'
})

const i18n = useI18n()
const {$setPageSeo, $scrollToTop} = useNuxtApp()

$setPageSeo({title: i18n.t('seo.landing.title'), description: i18n.t('seo.landing.description')})

useHead({
  script: [
    {
      src: 'https://unpkg.com/@rive-app/canvas@2.9.1',
      type: 'text/javascript',
      async: true
    }
  ]
})

const showReferralModal = ref<boolean>(false)
const canSeeConfirmationModal = ref<boolean>(false)
const registrationEmail = ref<string>('')

// define route from 'vue'
const route = useRoute()

const {referral} = route.query

const {overwrite} = await useSession()

if (referral) {
  showReferralModal.value = true
  // Set referral code value in session
  overwrite({referral})
}

const register = () => {
  $scrollToTop()
}

const toggleReferralModal = (value: boolean, email: string, showConfirmation: boolean) => {
  showReferralModal.value = value
  registrationEmail.value = email
  if (showConfirmation) {
    toggleConfirmationModal(true, email)
  }
}

const toggleConfirmationModal = (value: boolean, email: string) => {
  canSeeConfirmationModal.value = value
  registrationEmail.value = email
}
</script>
