<template>
  <div class="flex flex-col items-center w-full lg:w-[1440px] my-[50px] md:my-[100px]">
    <h2
      class="font-montserrat-normal text-cs-black dark:text-white text-[34px] md:text-[50px] lg:text-[58px] w-[320px] md:w-[608px] lg:w-[678px] text-center font-bold"
      data-aos="zoom-in"
    >
      {{ $t('landing.communityTitle') }}
    </h2>
    <div
      class="my-[50px] font-montserrat-normal text-cs-black dark:text-white text-[16px] md:text-[20px] font-medium leading-[30px] w-[320px] md:w-[608px] lg:w-[678px] text-center"
      data-aos="zoom-in"
    >
      <p>
        {{ $t('landing.communitySubtitle') }}
      </p>
    </div>
    <div class="flex justify-center gap-[50px] mx-5">
      <LandingCommunityGallery :data="COMMENTS" />
    </div>
    <div class="flex flex-col lg:flex-row mt-[50px] md:mt-[150px] justify-center items-center" data-aos="fade-up">
      <LandingCommunityText
        v-for="label in LABELS_FIRST_ROW"
        :bgColor="label.bgColor"
        :darkBgColor="label.darkBgColor"
        :darkTextColor="label.darkTextColor"
        :text="label.text"
        :textColor="label.textColor"
        class="md:mr-5 mb-5 lg:mb-0"
      />
    </div>
    <div class="flex flex-col lg:flex-row justify-center items-center lg:mt-5" data-aos="fade-up">
      <LandingCommunityText
        v-for="label in LABELS_SECOND_ROW"
        :bgColor="label.bgColor"
        :darkBgColor="label.darkBgColor"
        :darkTextColor="label.darkTextColor"
        :text="label.text"
        :textColor="label.textColor"
        class="md:mr-5 mb-5 lg:mb-0"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import LandingCommunityText from "~/components/ui/atoms/landing/LandingCommunityText.vue";

const COMMENTS = [
  {
    imgSrc: "/landing/whale.svg",
    realName: "ADA Whale",
    text: "Crypto media has failed us pretty badly over the years, so I’m a fan of Cardano becoming more self-sufficient on the news and content side. We’ve got a lot of great content creators, but recently especially @CardanoSpot ... stood out to me. Wanted to shout them out",
    userName: "@cardano_whale"
  },
  {
    imgSrc: "/landing/clover.svg",
    realName: "Clover Nodes",
    text: "Thank you for the highlight! Make sure to sign-up to Cardano Spot! Lots of great content on there.",
    userName: "@CloverNodes"
  },
  {
    imgSrc: "/landing/alfred.svg",
    realName: "Alfred Moesker",
    text: "There is now an answer to the question \"where do i find said information about #Cardano?\"  And the answer is .... @Cardanospot, the new Cardano Community social media platform and source of information, created by @emurgo_io.  Now in Beta, get jiggy with it!",
    userName: "@bigezdaddy2017"
  },
  {
    imgSrc: "/landing/nodo.svg",
    realName: "NODO",
    text: "As both our projects share the mission to bring awareness of Web3 innovations to mass communities...NODO is excited to further collaborate with the team at Cardano Spot to expand our ecosystems, communities, and impact on the blockchain industry and the world",
    userName: "@Official_NODO"
  },
  {
    imgSrc: "/landing/lucas.svg",
    realName: "Lucas Macchiavelli",
    text: "Cardano Spot is an exceptional platform for publishing my content, as well as a valuable collaborator in our joint efforts to increase the adoption of Cardano worldwide!",
    userName: "@LucasMacchia2"
  }
];

const LABELS_FIRST_ROW = [{
  bgColor: "#F6F5F4",
  darkBgColor: "#484848",
  text: "Welcome to Cardano Spot",
  textColor: "#312D3C",
  darkTextColor: "#FFF"
},
  {
    bgColor: "#FFEFD2",
    darkBgColor: "#584F3D",
    text: "Bienvenido a Cardano Spot",
    textColor: "#EDA32C",
    darkTextColor: "#EDA32C"
  },
  {
    bgColor: "#F7FFD8",
    darkBgColor: "#4A5326",
    text: "Cardano Spotへようこそ",
    textColor: "#B5CC5D",
    darkTextColor: "#B5CC5D"
  }
];

const LABELS_SECOND_ROW = [{
  bgColor: "#FFF7F7",
  darkBgColor: "#633030",
  text: "Selamat datang di Cardano Spot",
  textColor: "#FF7F7F",
  darkTextColor: "#FF7F7F"
},
  {
    bgColor: "#EAF4FF",
    darkBgColor: "#253C56",
    text: "Cardano Spot में आपका स्वागत है।",
    textColor: "#61A9FD",
    darkTextColor: "#61A9FD"
  }];
</script>
